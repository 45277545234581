import React from 'react';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile/profile-icon-with-bg.svg';

import { IUserNameCardProps } from './types/user-name-card-props.interface';

import * as S from './UserNameCard.styled';

export const UserNameCard: React.FC<IUserNameCardProps> = ({ fullName }) => {
  return (
    <S.UserInfoWrapper>
      <S.UserInfoCard>
        <ProfileIcon />
        <S.UserName>{fullName}</S.UserName>
      </S.UserInfoCard>
    </S.UserInfoWrapper>
  );
};
