import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const Container = styled.div`
  padding-bottom: 48px;
  background: linear-gradient(#0e46c0 220px, #f4f4f7 220px);

  @media (max-width: ${BreakPoint.TabletTop}) {
    background: linear-gradient(#0e46c0 160px, #f4f4f7 160px);
  }
`;

export const PageTitle = styled.h1`
  padding: 32px 24px;
  font-family: ${FontFamily.Montserrat};
  font-size: 40px;
  font-weight: 800;
  line-height: 58px;
  text-align: left;
  color: #fff;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    padding: 32px 24px 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 32px 16px;
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 24px 16px 32px;
  }
`;

export const ProfileTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
    padding: 24px 0;
  }
`;

export const TabsContentWrapper = styled.div`
  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
  }
`;

export const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px;
  }
`;
