import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { TProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

import { Tabs } from 'components/ui/Tabs';
import { AppRoute } from 'utils/route/app-route';

import { UserNameCard } from './components/ui/UserNameCard';
import { profileTabItems } from './constants/profile-tab-items.const';
import { useActiveTabValue } from './hooks/useActiveTabValue';

import * as S from './PageProfile.styled';

export const PageProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const activeTabValue = useActiveTabValue();

  const fullName = `${profile.firstname} ${profile.lastname}`;

  const handleTabClick = (newTabValue: string) =>
    navigate(AppRoute.ProfileTab(newTabValue as TProfileTabName));

  useEffect(() => {
    void dispatch(requestProfileDataThunkAction());
  }, [dispatch]);

  return (
    <S.Container>
      <S.PageTitle>My profile</S.PageTitle>
      <UserNameCard fullName={fullName} />
      <S.ProfileTabsWrapper>
        <Tabs
          tabItems={profileTabItems}
          activeTabValue={activeTabValue}
          handleTabClick={handleTabClick}
        />
        <S.TabsContentWrapper>
          <S.TabsContent>
            <Outlet />
          </S.TabsContent>
        </S.TabsContentWrapper>
      </S.ProfileTabsWrapper>
    </S.Container>
  );
};
