import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TWalletAddressChangeStep } from 'pages/PageDashboard/types/wallet-adress-change-step.type';
import { IProfileData } from 'store/api/cuverse-api/profile/types/profile-response.interface';
import { IWallets } from 'store/api/cuverse-api/profile/types/wallet-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TWalletChangeStatus } from 'types/wallet/wallet-change-status.type';

import {
  requestProfileDataThunkAction,
  requestWalletApproveOtpThunkAction,
  requestWalletsDataThunkAction,
  updateProfileDataThunkAction,
  updateWalletsDataThunkAction,
} from './profile.thunk-actions';

export interface IProfileState {
  profile: IProfileData;
  profileFetchStatus: TFetchStatus;
  wallets: IWallets;
  walletsFetchStatus: TFetchStatus;
  walletChangeStatus: TWalletChangeStatus;
  walletApproveFetchStatus: TFetchStatus;
  newWalletAddress: string | null;
  changeWalletAddressStep: TWalletAddressChangeStep;
  debugError: string | null;
}

export const initialState: IProfileState = {
  profile: {
    firstname: '',
    lastname: '',
    id: 0,
    avatar: '',
    level: 0,
    birthdate: '',
    email: '',
    country: '',
    timezone: '',
    state: '',
    city: '',
    zip: '',
    address: '',
    verificationStatusCode: 0,
    verificationStatus: '',
    walletLockedUntil: 0,
    is2FAEnabled: false,
    isApproved: false,
    isLocked: false,
    isManager: false,
  },
  profileFetchStatus: 'initial',
  wallets: {
    btc: '',
    ltc: '',
    eth: '',
    heco: '',
  },
  walletsFetchStatus: 'initial',
  walletChangeStatus: 'initial',
  walletApproveFetchStatus: 'initial',
  newWalletAddress: null,
  changeWalletAddressStep: 'initial',
  debugError: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setWalletChangeStatusAction: (state, { payload }: { payload: TWalletChangeStatus }) => {
      state.walletChangeStatus = payload;
    },
    setNewWalletAddressAction: (state, { payload }: { payload: string | null }) => {
      state.newWalletAddress = payload;
    },
    setChangeWalletAddressStepAction: (state, action: PayloadAction<TWalletAddressChangeStep>) => {
      state.changeWalletAddressStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestProfileDataThunkAction.pending, (state) => {
        state.profileFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(
        requestProfileDataThunkAction.fulfilled,
        (state, action: PayloadAction<IProfileData>) => {
          state.profileFetchStatus = 'fulfilled';
          state.profile = action.payload;
        },
      )
      .addCase(requestProfileDataThunkAction.rejected, (state, error) => {
        state.profileFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });

    builder
      .addCase(updateProfileDataThunkAction.pending, (state) => {
        state.profileFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(
        updateProfileDataThunkAction.fulfilled,
        (state, action: PayloadAction<IProfileData>) => {
          state.profileFetchStatus = 'fulfilled';
          state.profile = action.payload;
        },
      )
      .addCase(updateProfileDataThunkAction.rejected, (state, error) => {
        state.profileFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });

    builder
      .addCase(requestWalletsDataThunkAction.pending, (state) => {
        state.walletsFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(
        requestWalletsDataThunkAction.fulfilled,
        (state, action: PayloadAction<IWallets>) => {
          state.walletsFetchStatus = 'fulfilled';
          state.wallets = action.payload;
        },
      )
      .addCase(requestWalletsDataThunkAction.rejected, (state, error) => {
        state.walletsFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });

    builder
      .addCase(requestWalletApproveOtpThunkAction.pending, (state) => {
        state.walletApproveFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(requestWalletApproveOtpThunkAction.fulfilled, (state) => {
        state.walletApproveFetchStatus = 'fulfilled';
      })
      .addCase(requestWalletApproveOtpThunkAction.rejected, (state, error) => {
        state.walletApproveFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });

    builder
      .addCase(updateWalletsDataThunkAction.pending, (state) => {
        state.walletsFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(updateWalletsDataThunkAction.fulfilled, (state, action: PayloadAction<IWallets>) => {
        state.walletsFetchStatus = 'fulfilled';
        state.wallets = action.payload;
      })
      .addCase(updateWalletsDataThunkAction.rejected, (state, error) => {
        state.walletsFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });
  },
});

export const {
  setWalletChangeStatusAction,
  setNewWalletAddressAction,
  setChangeWalletAddressStepAction,
} = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
