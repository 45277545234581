import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';

export const UserInfoWrapper = styled.div`
  padding: 0 24px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 0 16px;
  }
`;

export const UserInfoCard = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  & svg {
    flex-shrink: 0;
    width: 160px;
    height: 160px;
  }

  @media (max-width: ${BreakPoint.LaptopTop}) {
    gap: 16px;

    & svg {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
    border-radius: 16px;

    & svg {
      width: 80px;
      height: 80px;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
    min-height: 152px;
  }
`;

export const UserName = styled(SText)`
  width: min-content;
  overflow: auto;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  word-wrap: break-word;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
  }
`;
