import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as WithdrawIcon } from 'assets/icons/button-icons/upload.svg';
import { DecimalPlaces } from 'constants/decimals/decimal-places';
import { FormApi } from 'final-form';

import { CurrencyBadge } from 'components/ui/CurrencyBadge';
import { formatInputValue } from 'utils/formatters/format-input-value.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isGreaterOrEqual } from 'utils/validators/is-greater-or-equal';
import { isLessOrEqual } from 'utils/validators/is-less-or-equal';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { MINIMUM_BTC_WITHDRAW_AMOUNT } from './constants/minimum-withdraw-amount.const';
import { NETWORK_COMMISSION_AMOUNT_BTC } from './constants/network-commission-amount-btc.const';
import {
  AMOUNT_LABEL_TEXT,
  MINIMUM_WITHDRAWAL_AMOUNT_TEXT,
  NETWORK_COMMISSION_TEXT,
  SAVED_WALLET_PROMPT,
  WALLET_LABEL_TEXT,
} from './constants/ui-text.const';
import { IWithdrawBtcFormValues } from './types/withdraw-btc-form-values.interface';

import * as S from './WithdrawForm.styled';

interface IWithdrawProps {
  btcAmountInWallet: number;
  btcUsdRate: number;
  savedWallet: string;
  handleFormSubmit: (values: IWithdrawBtcFormValues) => void;
}

export const WithdrawForm: React.FC<IWithdrawProps> = ({
  btcAmountInWallet,
  btcUsdRate,
  savedWallet,
  handleFormSubmit,
}) => {
  const [btcAmount, setBtcAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState('≈ $0');

  const handleBtcAmountChange = (
    value: string,
    form: FormApi<IWithdrawBtcFormValues, Partial<IWithdrawBtcFormValues>>,
  ) => {
    const formattedAmount = formatInputValue(value, DecimalPlaces.Btc);
    setBtcAmount(formattedAmount);
    setUsdAmount(`≈ $${formatUsdOutput(Number(formattedAmount) * btcUsdRate)}`);
    form.change('amount', formattedAmount);
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={{ amount: btcAmount }}>
      {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
        <S.Form onSubmit={handleSubmit}>
          <S.FieldsWrapper $isFailed={submitFailed}>
            <Field
              name="amount"
              validate={composeValidators([
                isRequired,
                isNumber,
                isGreaterOrEqual(MINIMUM_BTC_WITHDRAW_AMOUNT),
                isLessOrEqual(btcAmountInWallet),
              ])}
            >
              {({ input, meta }) => (
                <S.AmountLabel>
                  <S.LabelText>{AMOUNT_LABEL_TEXT}</S.LabelText>
                  <S.WithdrawAmountInput
                    {...input}
                    type="text"
                    cryptoCurrency="btc"
                    rightText={usdAmount}
                    hasErrors={meta.error && submitFailed}
                    onChange={(evt) => handleBtcAmountChange(evt.currentTarget.value, form)}
                    autoComplete="off"
                    autoFocus={true}
                  />
                  <S.InputDescriptionText>{MINIMUM_WITHDRAWAL_AMOUNT_TEXT}</S.InputDescriptionText>
                  {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                </S.AmountLabel>
              )}
            </Field>

            <S.NetworkCommissionInfo>
              <S.NetworkCommissionTitle>{NETWORK_COMMISSION_TEXT}</S.NetworkCommissionTitle>
              <S.NetworkCommissionWrapper>
                <CurrencyBadge currency="btc" />
                <S.CommissionAmount>{NETWORK_COMMISSION_AMOUNT_BTC}</S.CommissionAmount>
              </S.NetworkCommissionWrapper>
            </S.NetworkCommissionInfo>

            <S.WalletAddressWrapper>
              <S.WalletTitle>{WALLET_LABEL_TEXT}</S.WalletTitle>
              <S.SavedWalletContainer>
                <S.WalletAddress>{savedWallet}</S.WalletAddress>
                <S.WalletPrompt>{SAVED_WALLET_PROMPT}</S.WalletPrompt>
              </S.SavedWalletContainer>
            </S.WalletAddressWrapper>
          </S.FieldsWrapper>

          <S.WithdrawButton
            type="submit"
            text="Withdraw"
            disabled={submitFailed && hasValidationErrors}
            icon={<WithdrawIcon />}
          />
        </S.Form>
      )}
    </Form>
  );
};
